var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "printableContent" },
    [
      _c("h1", { staticClass: "mb-4" }, [
        _vm._v("Relatório de Pontos de Professores"),
      ]),
      _c(
        "div",
        { staticClass: "d-flex mb-3" },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "light" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({ name: "teacher-points-reports" })
                },
              },
            },
            [_vm._v("Voltar")]
          ),
          _c(
            "b-button",
            {
              staticClass: "ml-2",
              attrs: { variant: "success" },
              on: { click: _vm.printReport },
            },
            [_vm._v("Imprimir Relatório")]
          ),
        ],
        1
      ),
      _vm.report
        ? _c(
            "b-card",
            { staticClass: "mb-4", attrs: { "no-body": "" } },
            [
              _c(
                "b-card-body",
                [
                  _c("h4", { staticClass: "mb-3" }, [
                    _vm._v("Resumo do Relatório"),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("p", [
                        _c("strong", [_vm._v("ID do Relatório:")]),
                        _vm._v(" " + _vm._s(_vm.report.id)),
                      ]),
                      _c("p", [
                        _c("strong", [_vm._v("Data de Criação:")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("datePT")(_vm.report.created_at)) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("p", [
                        _c("strong", [_vm._v("Total de Itens:")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.report.items ? _vm.report.items.length : ""
                            ) +
                            " "
                        ),
                      ]),
                      _c("p", [
                        _c("strong", [_vm._v("Valor Total:")]),
                        _vm._v(
                          " " + _vm._s(_vm._f("toCurrency")(_vm.report.total))
                        ),
                      ]),
                    ]),
                  ]),
                  _c("hr"),
                  _c("h5", { staticClass: "mb-3" }, [
                    _vm._v("Valores a Pagar por Unidade"),
                  ]),
                  _c("b-table", {
                    attrs: {
                      items: _vm.unitSummary,
                      fields: _vm.unitSummaryColumns,
                      responsive: "",
                      small: "",
                      hover: "",
                      striped: "",
                      id: "unitSummaryTable",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(total_to_pay)",
                          fn: function (data) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("toCurrency")(data.item.total_to_pay)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2080605291
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "no-border-collapse": true,
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              striped: "",
              busy: _vm.loading,
              hover: "",
              id: "printMe",
              "empty-text": "Nenhum Registro encontrado.",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(real_workload_level_medium)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(data.item.hours_level_medium) +
                        "h (" +
                        _vm._s(
                          _vm.convertDecimalHoursToTime(
                            data.item.hours_level_medium
                          )
                        ) +
                        ") "
                    ),
                  ]
                },
              },
              {
                key: "cell(value_hour_medium)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("toCurrency")(data.item.value_hour_medium)
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(real_workload_level_higher)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(data.item.hours_level_higher) +
                        "h (" +
                        _vm._s(
                          _vm.convertDecimalHoursToTime(
                            data.item.hours_level_higher
                          )
                        ) +
                        ") "
                    ),
                  ]
                },
              },
              {
                key: "cell(value_hour_higher)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("toCurrency")(data.item.value_hour_higher)
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(payment_value)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toCurrency")(data.item.total_to_pay)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }