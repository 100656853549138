<template>
  <div ref="printableContent">
    <h1 class="mb-4">Relatório de Pontos de Professores</h1>

    <div class="d-flex mb-3">
      <b-button
        variant="light"
        @click="$router.push({ name: 'teacher-points-reports' })"
        >Voltar</b-button
      >
      <b-button variant="success" class="ml-2" @click="printReport"
        >Imprimir Relatório</b-button
      >
    </div>

    <!-- Resumo do Relatório -->
    <b-card no-body class="mb-4" v-if="report">
      <b-card-body>
        <h4 class="mb-3">Resumo do Relatório</h4>
        <div class="row">
          <div class="col-md-6">
            <p><strong>ID do Relatório:</strong> {{ report.id }}</p>
            <p>
              <strong>Data de Criação:</strong>
              {{ report.created_at | datePT() }}
            </p>
          </div>
          <div class="col-md-6">
            <p>
              <strong>Total de Itens:</strong>
              {{ report.items ? report.items.length : "" }}
            </p>
            <p><strong>Valor Total:</strong> {{ report.total | toCurrency }}</p>
          </div>
        </div>
        <hr />
        <h5 class="mb-3">Valores a Pagar por Unidade</h5>
        <b-table
          :items="unitSummary"
          :fields="unitSummaryColumns"
          responsive
          small
          hover
          striped
          id="unitSummaryTable"
        >
          <template #cell(total_to_pay)="data">
            {{ data.item.total_to_pay | toCurrency }}
          </template>
        </b-table>
      </b-card-body>
    </b-card>

    <!-- Tabela de Professores -->
    <b-card no-body>
      <b-table
        :no-border-collapse="true"
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        :busy="loading"
        hover
        id="printMe"
        empty-text="Nenhum Registro encontrado."
      >
        <template #cell(real_workload_level_medium)="data">
          {{ data.item.hours_level_medium }}h ({{
            convertDecimalHoursToTime(data.item.hours_level_medium)
          }})
        </template>
        <template #cell(value_hour_medium)="data">
          {{ data.item.value_hour_medium | toCurrency }}
        </template>
        <template #cell(real_workload_level_higher)="data">
          {{ data.item.hours_level_higher }}h ({{
            convertDecimalHoursToTime(data.item.hours_level_higher)
          }})
        </template>
        <template #cell(value_hour_higher)="data">
          {{ data.item.value_hour_higher | toCurrency }}
        </template>

        <template #cell(payment_value)="data">
          {{ data.item.total_to_pay | toCurrency }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { BButton, BCard, BCardBody, BTable } from "bootstrap-vue";

export default {
  components: {
    BTable,
    BButton,
    BCard,
    BCardBody,
  },
  data() {
    return {
      loading: false,
      dataItems: [],
      unitSummary: [],
      report: {}, // Adicionando os dados do relatório
      tableColumns: [
        { key: "teacher_id", label: "ID" },
        { key: "teacher.full_name", label: "Professor" },
        { key: "teacher.cpf", label: "CPF" },
        { key: "teacher.type", label: "Tipo" },
        { key: "unit.name", label: "Unidade" },
        { key: "real_workload_level_medium", label: "Horas nível médio" },
        {
          key: "value_hour_medium",
          label: "Vlr Hr Médio",
        },
        { key: "real_workload_level_higher", label: "Horas nível superior" },
        {
          key: "value_hour_higher",
          label: "Vlr Hr Superior",
        },
        { key: "payment_value", label: "A pagar" },
        { key: "teacher.bank.bank.name", label: "Banco" },
        { key: "teacher.bank.account", label: "Conta" },
        { key: "teacher.bank.agency", label: "Agencia" },
      ],
      unitSummaryColumns: [
        { key: "unit_name", label: "Unidade" },
        { key: "total_to_pay", label: "Total a Pagar" },
      ],
    };
  },
  async created() {
    try {
      this.loading = true;
      const uuid = this.$route.params.uuid;

      const response = await this.$store.dispatch(
        "Grid/getReportsTeacherPointsByUuid",
        uuid
      );

      this.dataItems = response.items;
      this.report = response;

      this.updateUnitSummary();
    } catch (error) {
    } finally {
      this.loading = false;
    }
  },
  methods: {
    formatDate(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString("pt-BR", options);
    },

    convertDecimalHoursToTime(decimalHours) {
      const hours = Math.floor(decimalHours);
      const minutes = Math.round((decimalHours - hours) * 60);
      if (hours === 0) return `${minutes}min`;
      if (minutes === 0) return `${hours}h`;

      return `${hours}h ${minutes}min`;
    },

    updateUnitSummary() {
      const summary = {};

      this.dataItems.forEach((item) => {
        const unitName = item.unit.name;

        if (!summary[unitName]) {
          summary[unitName] = {
            unit_name: unitName,
            total_to_pay: 0,
          };
        }

        summary[unitName].total_to_pay += item.total_to_pay;
      });

      this.unitSummary = Object.values(summary);
    },
    printReport() {
      const content = this.$refs.printableContent.innerHTML;

      const printWindow = window.open("", "_blank");

      printWindow.document.write(`
        <html>
          <head>
            <title>Relatório de Pontos de Professores</title>
            <style>
              body {
                font-family: Arial, sans-serif;
              }
              .table {
                width: 100%;
                border-collapse: collapse;
              }
              .table th, .table td {
                border: 1px solid #ddd;
                padding: 8px;
              }
              .table th {
                background-color: #f2f2f2;
                text-align: left;
              }
            </style>
          </head>
          <body onload="window.print(); window.close();">
            <div class="container">
              ${content}
            </div>
          </body>
        </html>
      `);

      printWindow.document.close();
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 24px;
  font-weight: bold;
}
</style>
